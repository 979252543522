export default {
  colors: {
    primary: '#FFD717',
    secondary: '#0D63A5',
    accent1: '#083358',
    accent2: '#001F3F',
    primaryText: '#222222',
    grey100: 'hsl(0, 0%, 90%)',
    grey850: 'hsl(0, 0%, 15%)',
    black: 'hsl(0, 0%, 0%)',
  },

  fontSize: {
    xxs: '1.2rem',
    xs: '1.6rem',
    s: '1.8rem',
    m: '2rem',
    l: '2.4rem',
    xl: '2.8rem',
    xxl: '3.4rem',
  },

  fontWeight: {
    light: 300,
    regular: 400,
    bold: 600,
    extrabold: 900,
  },

  dimensions: {
    navbarHeight: '70px',
    footerHeight: '80px',
  },
};
