import styled from 'styled-components';

export const StyledWrapperFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.accent2};
  height: ${({ theme }) => theme.dimensions.footerHeight};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHeading = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
`;
