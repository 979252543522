import React from 'react';
import {
  StyledWrapperNavbar,
  StyledContact,
  StyledPhoneIcon,
  StyledEmailIcon,
} from './Navbar.styles';

const Navbar = () => {
  return (
    <StyledWrapperNavbar>
      <StyledContact>
        <StyledEmailIcon />
        kontakt@knarent.pl
      </StyledContact>
      <StyledContact>
        <StyledPhoneIcon />
        514 902 882
      </StyledContact>
    </StyledWrapperNavbar>
  );
};

export default Navbar;
