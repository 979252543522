import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`  
  *, *::after, *::before {
    margin: 0;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%
  }
  
  body {
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    margin: 0px;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  P {
    font-family: 'Work Sans', sans-serif;
  }
`;
