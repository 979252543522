import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from 'theme/globalStyle';
import { ThemeProvider } from 'styled-components';
import theme from 'theme/mainTheme';
import Navbar from 'components/molecules/navbar/Navbar.component';
import Footer from 'components/molecules/footer/Footer.components';

const MainTemplate = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Navbar />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainTemplate;
