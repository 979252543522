import styled, { css } from 'styled-components';
import { AiFillPhone, AiFillMail } from 'react-icons/ai';

export const StyledWrapperNavbar = styled.nav`
  height: ${({ theme }) => theme.dimensions.navbarHeight};
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10rem;

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const StyledContact = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.m};
  line-height: 24px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: 20px;
  }
`;

const IconStyles = css`
  margin-right: 0.6rem;

  @media (min-width: 600px) {
    margin-right: 1.2rem;
  }
`;

export const StyledPhoneIcon = styled(AiFillPhone)`
  ${IconStyles}
`;
export const StyledEmailIcon = styled(AiFillMail)`
  ${IconStyles}
`;
