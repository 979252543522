import React from 'react';
import { StyledWrapperFooter, StyledHeading } from './Footer.styles';

const Footer = () => {
  return (
    <StyledWrapperFooter>
      <StyledHeading>Brzozówka 55A, 32-340 Wolbrom</StyledHeading>
    </StyledWrapperFooter>
  );
};

export default Footer;
